<template>
<Favorite :widget="widget" :data="data" :editable="true"></Favorite>
</template>

<script>
// این ویو برای ماژول نشان شده ها و یا علاقه مندی ها مورد استفاده قرار میگیرد
import Favorite from '@/components/Favorite.vue'

export default {
    name: 'FavoritesView',
    components: {
        Favorite
    },
    data: function () {
        return {
            widget: {},
            data: {},
        }
    },
    mounted() {
        // در متد مانت یک فانکشی رو از هلپر کال میکنیم جهت لود کردن داده های ویجت 
        this.$helpers.getWidgets({ widget_type: 'favorite', store: false }).then((widgets) => {
            let widget = widgets[0];

            this.widget = widget.widget;
            this.data = widget.data;
        });
    },
}
</script>
